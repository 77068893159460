import React from "react"
import { Link } from "gatsby"
import moment from "moment"

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const DealLink = ({ deal }) => {
    const theme = useTheme();

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardActionArea component={Link} to={deal.frontmatter.dealLink} target="_blank">
                <span className="corner-ribbon" style={{ backgroundColor: Math.round((deal.frontmatter.msrp - deal.frontmatter.price) / deal.frontmatter.msrp * 100) >= 50 ? theme.palette.error.main : theme.palette.info.main }}>{Math.round((deal.frontmatter.msrp - deal.frontmatter.price) / deal.frontmatter.msrp * 100)}% off</span>
                <CardMedia
                    component="img"
                    height="250"
                    image={deal.frontmatter.thumbnail}
                    alt={deal.frontmatter.title}
                    sx={{ width: 'initial', margin: '0 auto', padding: '10px' }}
                />
                <Divider />
                <CardContent>
                    <Typography variant="overline" sx={{ textTransform: 'uppercase', lineHeight: '1' }}>{deal.frontmatter.merchant} &middot; {moment(deal.frontmatter.date).fromNow()}</Typography>
                    <Typography variant="h6" component="h3" sx={{ lineHeight: '1.2' }}>{deal.frontmatter.title}</Typography>
                    <Box sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', pt: 2}}>
                        <Typography variant="h5" component="span" sx={{color: theme.palette.info.main}}>${deal.frontmatter.price}</Typography>
                        <Typography variant="body" sx={{
                            '&::before': {
                                content: '"MSRP"',
                                whiteSpace: 'pre',
                                fontSize: '.75rem',
                                display: 'block'
                            }
                        }}>
                            ${deal.frontmatter.msrp}
                        </Typography>
                        <Typography variant="body" sx={{
                            '&::before': {
                                content: '"AVG"',
                                whiteSpace: 'pre',
                                fontSize: '.75rem',
                                display: 'block'
                            }
                        }}>
                            ${deal.frontmatter.average}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
export default DealLink