import React from "react"
import { Link } from "gatsby"

import video from "../images/money.mp4";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export default () => {
    const theme = useTheme();

    return (
        <Box component="section" style={{ position: 'relative' }}>
            <Box sx={{
                width: {md: '100%'},
                height: {xs: '500px', md: 'initial'},
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                video: {
                    width: {xs: 'initial', md: '100%'},
                    height: {xs: '100%', md: 'initial'}
                }
            }}>
            <video autoPlay muted loop id="myVideo">
                <source src={video} type="video/mp4" />
            </video>
            </Box>
            <Box sx={{
                position: 'absolute',
                top: '10%',
                right: {xs: 'initial', sm: '5%'},
                left: {md: '20vw'},
                backgroundColor: 'rgba(0,0,0,.4)',
                fontSize: '6vw',
                padding: {xs: '20px 10px', md: '50px'},
                margin: '10px'
            }}>
                <Typography variant="h4" component="h1" sx={{color: '#d2fdff'}}>Great deals on quality products</Typography>
                <Typography variant="button" display="block" sx={{color:'#fff', fontStyle:'italic', fontSize:'1.2rem'}}>To save you money</Typography>
                <Typography variant="body" display="block" sx={{color: '#fff', pt: 2, fontSize: '20px'}}>We scour the internet for the best deals so you don't have to.</Typography>
                <Button variant="contained" color="primary" size="large" component={Link} to='/about'>Learn more</Button>
            </Box>
        </Box>
    )
}